.blog {
    background-color: #e9f9f0;
    padding-bottom: 20px;
}

.blog h1 {
    margin-top: 0px;
    padding-top: 5%;
    font-size: 65px;
}


.blog-lists {
    display: flex;
    width: 100%;
    margin-top: 7%;
    margin-bottom: 10%;
    flex-wrap: wrap;
    justify-content: space-between;
}

.blog-lists a {
    /* margin-right: 20px; */
    text-decoration: none;
    width: 30%;
    color: #025;
}

.blog-box {
    width: 100%;
    margin-right: 20px;
    margin-bottom: 10%;
}

.blog-box:hover {
    cursor: pointer;
    -webkit-box-shadow: 0px 2px 44px 10px rgba(186,186,186,1);
    -moz-box-shadow: 0px 2px 44px 10px rgba(186,186,186,1);
    box-shadow: 0px 2px 44px 10px rgba(186,186,186,1);
    border-top-right-radius: 40px;
    transition: 0.5s;
}

.blog-box img {
    width: 100%;
    border-top-right-radius: 40px;
}

.blog-box .blog-info h3 {
    height: 75px;
}

.blog-box .blog-info {
    background-color: #fff;
    padding: 20px 20px 30px 20px;
    height: 430px;
}

.blog-box .blog-info p {
    color: #627897;
    width: 100%;
}


.single-blog-post {
    display: flex;
    padding-bottom: 5%;
    padding-top: 5%;
    width: 100%;
}

.single-blog-post h2 {
    margin-top: 0px;
}

.single-blog-post p {
    color: #627897;
    width: 100%;
}

.single-blog-post .author {
    text-align: center;
    margin-bottom: 5%;
}

.single-blog-post .author img {
    width: 150px;
    border-radius: 150px;
    margin-bottom: 20px;
}

.single-blog-post .author p {
    margin-top: 0px;
    margin-bottom: 0px;
}

.single-blog-post .content {
    width: 60%;
}

.single-blog-post .content p {
    text-align: justify;
} 

.single-blog-top-image img {
    width: 100%;
}

.single-blog-post .sub-form {
    width: 40%;
  }
.features-heading {
    background-color: #002255;
    padding-top: 5%;
    padding-bottom: 5%;
}

.features-heading h1 {
    margin-top: 0px;
    font-size: 65px;
    color: #fff;
}

.features-heading p {
    color: #fff;
    margin-bottom: 0px;
}    

.features-tabs {
    padding-top: 3%;
    background-color: #f5f7fb;
}

.features-accordion {
    position: relative;
    padding-bottom: 5%;
    display: flex;
}

.features-accordion .features-accordion-screen {
    width: 70%;
    text-align: left;
    background-color: #fff;
    padding: 20px 0px 20px 50px;
    /* border-radius: 15px; */
    margin-bottom: 15px;
}

.features-accordion .features-accordion-screen h3 {
    margin: 0px;
}

.features-accordion h3.features-accordion-screen.active  {
    color: #00b35d;
}    

.features-accordion .features-accordion-screen.active {
    cursor: pointer;
    /* box-shadow: 10px 10px 21px -3px rgba(0,0,0,0.24); */
    background-color: #e9f9f0 !important;
    transition: 0.3s;
    border-left: 5px solid #00b35d;
}

 /* .features-accordion .features-accordion-info {
    width: 60%;
    float: right;
    position: absolute;
    right: 0px;
    top: 0px;
} */

.features-accordion .features-accordion-info p {
    width: 100%;
}

.features-half {
    display: flex;
    margin-top: 30px;
}



.features-accordion .features-accordion-screen:hover {
    cursor: pointer;
    -webkit-box-shadow: 10px 10px 21px -3px rgba(0,0,0,0.24);
    -moz-box-shadow: 10px 10px 21px -3px rgba(0,0,0,0.24);
    box-shadow: 10px 10px 21px -3px rgba(0,0,0,0.24);
    transition: 0.5s;
}

.features-accordion-heading, .features-accordion-content, .features-half div{
    width: 50%;

}


.features-accordion .features-accordion-info .active .close-btn {
    display: none;
}

@media only screen and (max-width: 767px) {
    .features-accordion .features-accordion-info .active {
        position: fixed;
        top: 0;
        left: 0;
        height: calc(100% - 4rem);
        background: white;
        z-index: 100000;
        margin: 1rem;
        padding: 16px;
        display: block;
        box-shadow: 0px 6px 18px rgba(0,0,0,0.15);
        border-radius: 1rem;
        overflow: scroll;
    }
    .features-accordion .features-accordion-info .active .close-btn {
        display: flex;
        justify-content: end;
    }
}

.platform-back {
    background-color: #002255;
    padding-top: 5%;
    padding-bottom: 5%;
}

.platform-heading {
    display: flex;
    align-items: end;
}

.platform-heading-left {
    width: 60%;
}

.platform-heading-right {
    text-align: right;
    width: 35%;
}

.platform-heading-right p {
    width: 100%;
    color: #5fd38d !important;
}

.platform-heading h1 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 65px;
    color: #fff;
}

.platform-heading p {
    color: #fff;
    margin-bottom: 0px;
}

.solutions-box {
    width: 33.3%;
    margin-right: 40px;
}

.solutions-box > p {
    margin-bottom: 5px;
} 

.solutions-box p {
    width: 100%;
}

.solutions-box p:last-child {
    margin-top: 0px;
}

.solutions-button {
    text-align: center;
    margin-top: 3%;
}

.why-choose {
    margin-top: 5%;
}

.choose-solutions  {
    margin-top: 3%;
}

.why-choose-content h2 {
    text-align: center;
}

.why-choose-box {
    display: flex;
    margin-top: 5%;
}

.why-choose-box p, .book-consultations-section p {
    width: 100%;
}

.why-choose-box p:first-child, .book-consultations-section p:first-child {
    margin-top: 0px;
}

.why-choose-box p:nth-child(even) {
    margin-top: 0px;
}

.why-choose-box p:nth-child(odd) {
    margin-bottom: 5px;
}

.why-choose-box-left, .why-choose-box-right  {
    width: 50%;
}

.why-choose-box-left img {
    width: 100%;
}

.why-choose-box-right {
    margin-left: 5%;
}

.solutions-back {
    background-color: #f5f7fb;
    margin-top: 3%;
    padding-bottom: 5%;
}

.solutions-pricing-content h2 {
    padding-top: 4%;
    text-align: center;
}

.pricing-button {
    text-align: center;
}

.book-consultations-section {
    background-color: #e9f9f0;
    text-align: center;
    padding-top: 5%;
}

select {
    color: #757575;
    width: 46.7%;
}

select:active {
    color: #000 !important;
}

.grow-button:hover {
    background-color: #5fd38d;
    border: 1px solid #5fd38d;
}


@media only screen and (max-width: 430px) {
    .platform-heading, .why-choose-box {
      flex-direction: column;
    }

    .platform-heading-left, .platform-heading-right, .solutions-box, .solutions-box p, .why-choose-box-left, .why-choose-box-right {
        width: 100%;
    }

    .platform-heading-right {
        text-align: left;
    }

    .platform-heading-left h1 {
        font-size: 45px;
    }

    .why-choose-box p:first-child {
        margin-top: 30px;
    }

    select {
        width: 98%;
    }

    .solutions-boxes {
        padding-top: 10%;
        text-align: center;
    }

    .solutions-box img {
        margin-top: 10%;
    }

    .pricing-info > p {
        padding-left: 30px;
        padding-top: 40px;
    }

  }

@media only screen and (max-width: 1440px) and (min-width: 1200px)  {
    .platform-heading-left {
        width: 70%;
    }

    select {
        width: 47%;
    }

    .pricing-info > p {
        padding-top: 40px;
    }
}
.top-back-image {
    background-image: url(/src/Top-img.png);
    background-size: cover;
    background-position: center;
}

.welcome-info {
    width: 50%;
    color: #fff;
    padding: 150px 0px 150px 0px;
}

.welcome-info h1 {
    font-size: 50px;
    margin-bottom: 0px;
}

.welcome-info h2 {
    color: #5fd38d;
    margin-top: 15px;
    font-size: 35px;
}
.welcome-info p {
    width: 80%;
}

.welcome-info button {
    width: 35%;
}
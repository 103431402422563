.features {
    padding: 50px 0px 30px 0px;
    background-color: #f5f7fb;
}

.features p {
    margin: 0 auto;
}


.accordion {
    margin: 50px 0px;
    overflow: hidden;
}

.accordion .accordion-box {
    /* display: flex; */
    align-items: center;
    margin-bottom: 15px;
}

.accordion .accordion-screen {
    width: 50%;
    text-align: left;
    background-color: #fff;
    padding: 0px 0px 10px 50px;
    /* border-radius: 20px; */
    margin-bottom: 15px;
    float: left;
}

.accordion .accordion-screen:hover {
    cursor: pointer;
    -webkit-box-shadow: 10px 10px 21px -3px rgba(0,0,0,0.24);
    -moz-box-shadow: 10px 10px 21px -3px rgba(0,0,0,0.24);
    box-shadow: 10px 10px 21px -3px rgba(0,0,0,0.24);
    transition: 0.3s;
}

.accordion .accordion-screen h3 {
    margin-bottom: 0px;
    margin-top: 10px;
}

.accordion .accordion-screen.active h3 {
    color: #00b35d;
}

.accordion .accordion-screen p {
    margin: 0px;
}

.accordion .accordion-info {
    width: 50%;
    float: right;
    position: absolute;
    right: 0px;
}

.accordion .accordion-screen.active {
    cursor: pointer;
    /* -webkit-box-shadow: 10px 10px 21px -3px rgba(0,0,0,0.24);
    -moz-box-shadow: 10px 10px 21px -3px rgba(0,0,0,0.24);
    box-shadow: 10px 10px 21px -3px rgba(0,0,0,0.24); */
    background-color: #e9f9f0 !important;
    transition: 0.5s;
    float: left;
    border-left: 5px solid #00b35d;
}

.accordion .accordion-info .active img {
    width: 450px;
    border-radius: 20px;
}

.inactive {
    display: none;
}
header {
    display: flex;
    align-items: center;
    width: 75%;
    padding-top: 20px;
    margin: 0 auto;
}

#menu {
    width: 100%;
} 

header .menu-logo {
    width: 25%;
}

header .menu-logo img {
    width: 60%;
}

#menu .menu-items {
    width: 100%;
    text-align: center;
    align-items: center;
    display: flex;
}

#menu .menu-items ul {
    display: flex;
    justify-content: center;
    list-style-type: none;
    width: 100%;
}

#menu .menu-items ul li {
    margin-right: 5%;
}

#menu .menu-items ul li a {
    text-decoration: none;
    color: #002255;
}

#menu .menu-items ul li a:hover {
    color: #00b35d;
    transition: 0.2s;
}

#menu .menu-items ul li ul.dropdown-menu {
    flex-direction: column;
    position: absolute;
    padding: 30px 0px 10px 20px;
    text-align: left;
    background-color: #fff;
    width: 10%;
    border-radius: 10px;
}

#menu .menu-items ul li ul.dropdown-menu li {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 15px;
}

#menu .menu-items ul li ul.dropdown-menu li img {
    vertical-align: bottom;
    width: 15px;
    margin-right: 5px;
}

#menu .menu-items ul li ul.dropdown-menu li:hover {
    background-color: #f2f2f2;
    cursor: pointer;
    border-radius: 10px;
}

#menu .menu-items ul li ul.dropdown-menu li a:hover {
    color: #002255;
}

#menu .menu-button {
    width: 35%;
    text-align: center;
}

#menu .menu-button button {
    background-color: #00b35d;
    color: #fff;
    font-weight: bold;
    border: 1px solid #00b35d;
    padding: 5px;   
    width: 55%;
    font-size: 18px;
    /* border-radius: 20px; */
    font-family: "ProximaNova";
    margin-top: 0px;
}

#menu .menu-button button:hover {
    background-color: #002255;
    border: 1px solid #002255;
    transition: 0.5s;
    color: #fff;

}


header .nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: #5fd38d;
    display: none;
    opacity: 0;
}

@media only screen and (max-width: 768px) {
   header .nav-btn {
        display: block;
        opacity: 1;
        text-align: right;
    }

    header .nav-btn svg {
        height: 2em;
        width: 2em;
    }

    header .menu-logo {
        width: 100%;
    }

    header .menu-logo img {
        width: 70%;
    }

    header nav {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: #fff;
        transition: 1s;
        transform: translateY(-100vh);
        z-index: 6;
    }

    header .responsive_nav {
        transform: none;
    }

    header .nav-close-btn {
        position: absolute;
        top: 0;
        right: 5%;
    }

    header #menu .menu-items, #menu .menu-items ul {
        flex-direction: column;
        text-align: left;
    }

    #menu .menu-items ul li {
        margin-left: 25%;
        font-size: 25px;
        margin-bottom: 15px;
    }

    #menu .menu-button {
        width: 100%;
    }

     
} 

.pricing {
    background-color: #f5f7fb;
    padding-bottom: 20px;
}

.pricing h1 {
    margin-top: 0px;
    padding-top: 5%;
    font-size: 65px;
}

.pricing .page-heading p, .pricing-form p {
    margin: 0 auto;
    font-size: 30px;
}


.pricing-boxes {
    display: flex;
    width: 100%;
    margin-top: 7%;
    margin-bottom: 10%;
}

.pricing-box {
    width: 100%;
    margin-right: 20px;
}

.third-green-back {
    background-color: #e9f9f0 !important;
}

.pricing-heading {
    background-color: #fff;
    color: #002255;
    padding: 5px 40px 5px 40px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    height: 160px;
    text-align: center;
}

.pricing-heading p { 
    width: 100%;
}

.pricing-info {
    background-color: #fff;
    height: 75%;
}

.pricing-info > p {
    margin-top: 0px;
    padding: 20px 0px 30px 50px;
    text-align: center;
    color: #9a9fab;
    font-weight: bold;
}

.pricing-info > p span {
    color: #00b35d;
    font-weight: bold;
    font-size: 35px;
}

.pricing-info ul {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 20px 0px 30px 50px;
    line-height: 1.5em;

}

.pricing-info ul li::marker {
    content: "✓ ";
}

.pricing-boxes .pricing-box:nth-child(2) .pricing-info .pricing-list ul, .pricing-boxes .pricing-box:nth-child(3) .pricing-info .pricing-list ul {
    padding-top: 0px;
}

.pricing-list p {
    padding-left: 30px;
    margin-bottom: 0px;
}

.pricing-list li {
    color: #9a9fab;
}

.divider {
    border: 1px solid #E3E7EC;
    display: block;
    width: 90%;
}


.pricing-price {
    background-color: #fff;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding-bottom: 50px;
    text-align: center;
} 

.pricing-price h3 {
    margin: 0px;
    padding: 15px 0px 15px 30px;
    color: #Fff;
}

@media only screen and (max-width: 430px)  {
    .pricing-boxes {
        flex-direction: column;
    }

    .pricing-box {
        margin-bottom: 30px;
    }
}

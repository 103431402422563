.use-cases-page {
    background-color: #e9f9f0;
}

.use-cases-page h1 {
    margin-top: 0px;
    padding-top: 5%;
    font-size: 65px;
}

.use-cases {
    display: flex;
    margin-top: 10%;
    padding-bottom: 10%;
    align-items: center;
}

.use-cases-img, .use-cases-form {
    width: 50%;
}

.use-cases-img img {
    width: 100%;
    border-top-right-radius: 40px;
}

.use-cases-form {
    background-color: #fff;
    margin-left: 5%;
    padding: 40px 40px 40px 100px;
    border-top-right-radius: 40px;
    box-shadow: 10px 10px 21px -3px rgba(0,0,0,0.44);
}

.use-cases-form input[type=text], .use-cases-form input[type=email] {
    border: none;
    border-bottom: 1px solid lightgray;
    width: 80%;
    margin-bottom: 40px;
}

.use-cases-form form {
    margin-top: 50px;
}

.use-cases-form input[type=text]::placeholder, .use-cases-form input[type=email]::placeholder {
    color: #002255;
    font-weight: 100;
    font-size: 16px;
}


.use-cases-form input[type=text]:focus-visible, .use-cases-form input[type=email]:focus-visible {
    outline: none;
}

.use-cases-form input[input = value] {
    font-size: 16px;
}

.use-cases-button {
    width: 80%;
    text-align: center;
}

.use-cases-button button {
    width: 40%;
}
@font-face {
  font-family: "GilroyBold";
  src: local(GilroyBold),
  url("./fonts/Gilroy/FontsFree-Net-Gilroy-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "ProximaNova";
  src: local(ProximaNova),
  url("./fonts/Proxima-Nova/FontsFree-Net-proxima_nova_reg-webfont.ttf") format("truetype");
}

html {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "ProximaNova";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 17px;
  color: #002255;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "GilroyBold";
}

h2 {
  font-size: 35px;
}

p {
  width: 80%;
  line-height: 1.5em;
}

.text-center {
  text-align: center;
}

.main-wrapper {
  width: 75%;
  margin: 0 auto;
}

.main-wrapper-tc {
  width: 60%;
  margin: 0 auto;
  padding-bottom: 5%;
} 

button, .sub-form input[type='submit'] {
  background-color: #00b35d;
  color: #fff;
  font-weight: bold;
  border: 1px solid #00b35d;
  padding: 5px;
  width: 170px !important;
  font-size: 18px;
  /* border-radius: 20px; */
  font-family: "ProximaNova";
  margin-top: 20px;
}


button:hover, .sub-form input[type='submit']:hover {
  cursor: pointer;
  background-color: #002255;
  border: 1px solid #002255;
  transition: all 0.5s;
  color: #fff;
}

.experience, .contact-us, .cases, .award-models {
  padding: 50px 0px 30px 0px;
}

.contact-us {
  background-color: #e9f9f0;
  margin-top: 30px;
}

/* .contact-us .contact-us-form {
  margin-top: 30px;
} */

.contact-us .contact-us-form input[type=text], .contact-us .contact-us-form input[type=email], select {
  width: 45%;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
  background-color: #fff;
}

.contact-us .contact-us-form button:hover {
  cursor: pointer;
}

.experience p, .contact-us p, .award-models p {
  margin: 0 auto;
}

.experience-boxes {
  display: flex;
  justify-content: space-between;
  margin: 50px 0px;
}

.experience-boxes .experience-box {
  width: 23%;
  background-color: #e9f9f0;
  border-radius: 30px;
  padding: 10px 0px;
}

.experience-boxes .experience-box h3 {
  margin-top: 0px;
  height: 50px;
}


footer {
  background-color: #07133b;
  overflow: hidden;
  padding-bottom: 30px;
}

footer h2 {
  color: #fff;
}

footer .menu-items p, footer .company-part p {
  color: #9a9fab !important;
}

footer .company-part {
  display: flex;
  align-items: flex-end;
}

footer .company-part div:first-child, footer .company-part div:last-child {
  width: 50%;
}

footer .company-part div:last-child {
  text-align: right;
}


.cases-boxes {
  display: flex;
  margin-top: 50px;
}

.cases .cases-box {
  width: 100%;
}

.cases .cases-box img {
  width: 85%;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.cases .cases-box a {
  border: 2px solid #5fd38d;
  /* border-radius: 20px; */
  color: #002255;
  padding: 10px;
  text-decoration: none;
  font-weight: bold;
  font-size: 15px;
  display: block;
  width: 35%;
  margin-top: 40px;
  text-align: center;
}

.cases .cases-box a:hover {
  border: 2px solid #002255;
  color: #fff;
  background-color: #002255;
  transition: 0.5s;
}

.cases .cases-box h3 {
  width: 85%;
  height: 13%;
}

.cases .cases-box p {
  height: 20%;
}

.award-models {
  background-color: #002255;
  color: #fff;
}

.award-models .shapes {
  display: flex;
  justify-content: center;
  margin: 50px auto;
}

.award-models .shapes .left-shape-one {
  background-color: #bfeed3;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  width: 20%;
  height: 250px;
}

.award-models .shapes .left-shape-two, .award-models .shapes .right-shape-two {
  background-color: #5fd38d;
  border-radius: 30px;
  width: 7%;
  height: 250px;
}

.award-models .shapes .right-shape-one {
  background-color: #bfeed3;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  width: 20%;
  height: 250px;
}

.award-models .shapes img {
  border-radius: 30px;
  /* height: 250px;
  width: 400px; */
}

.award-models .models-info {
  display: flex;
}

.award-models .models-info p {
  margin: 0;
}


.footer {
  display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
    /* align-items: center; */
    width: 100%;
}

.footer .menu-items {
  width: 70%;
}

.footer .menu-button {
  width: 30%;
  text-align: right;
}

.footer button {
  width: 35%;
  margin-bottom: 17px;
}

.footer .menu-items ul {
  display: flex;
  list-style-type: none;
  padding-left: 0px;
}

.footer .menu-items ul li {
  margin-right: 5%;
}

.footer .menu-items ul li a {
  text-decoration: none;
  color: #9a9fab;
}

.footer .menu-items ul li a:hover {
  color: #00b35d;
}

footer .social-icons {
  text-align: right;
}

footer .social-icons a {
  color: #00b35d;
  font-size: 20px;
  margin-right: 15px;
  text-decoration: none;
}

footer .social-icons a:last-child {
  margin-right: 0px;
}

footer .social-icons a:hover {
  color: #00b35d;
}

footer .company-part p {
  color: #fff;
  width: 100%;
} 

footer .company-part a {
  color: #9a9fab;
  text-decoration: none;
}

footer .company-part a:hover {
  color: #00b35d;
} 



.pricing-inputs {
  padding: 5%;
  width: 50%;
  margin: 0 auto;
}

.not-found {
  background-color: #e9f9f0;
  height: 80vh;
  display: flex;
  text-align: center;
  align-items: center;

}

.not-found h1 {
  margin-top: 0px;
  font-size: 65px;
}

.tc {
  background-color: #e9f9f0;
  padding-bottom: 20px;
}

.tc p, .not-found p {
  width: 100%;
}

.tc p:last-child {
    margin-bottom: 0px;
}

.tc h1 {
  margin-top: 0px;
  padding-top: 5%;
  font-size: 65px;
}


._hj_feedback_container ._hj-ETLL8__MinimizedWidgetMiddle__label {
  width: 40px !important;
}

.thank-you {
  background-color: #e9f9f0;
  height: 80vh;
}

.thank-you h1 {
  margin-top: 0px;
}

.thank-you-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  flex-direction: column;
}

.consultant-thank-you {
  background-color: #e9f9f0;
  height: 120vh;
}

.consultant-thank-you h1 {
  margin-top: 0px;
  padding-top: 10%;
}

.consultant-thank-you-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; 
  height: 120vh;
  padding-bottom: 10%;
}



.sub-form input[type='text'], .sub-form input[type='email'] {
    border-radius: 30px;
    padding: 10px;
    border: 1px solid #025;
    margin-bottom: 10px;
}


._hj-eEC5y__ExpandedWidget__closeButton {
  width: 27px !important;
}


.calendly-inline-widget {
  min-width: 320px;
  height: 700px;
  display: block;
}



@media only screen and (max-width: 430px) {
  #root {
    overflow: hidden;
  }

  #menu .menu-items ul li ul.dropdown-menu {
    background-color: #fff !important;
    margin-top: 15px !important;
    margin-left: -10px !important;
    width: 50% !important;
    padding: 15px 0px 10px 20px !important;
    box-shadow: 0px 2px 44px 10px rgba(186,186,186,1);
  }

  #menu .menu-items ul li ul.dropdown-menu li {
    margin-left: 0px !important;
  }

  #menu .menu-items ul li ul.dropdown-menu li a {
    color: #002255;
  } 

  .welcome-info, .testimonials-box, .experience-box, .pricing-inputs, .footer .menu-items, .footer button, .solutions-first-screen .solutions-first-screen-left, .solutions-first-screen .solutions-first-screen-right, .use-cases-img, .use-cases-form {
    width: 100% !important;
  }

  .welcome-info {
    padding: 50px 0px 50px 0px !important;
  }

  .welcome-info h1 {
    font-size: 40px !important;
  }

  .welcome-info button {
    width: 60% !important;
  }

  .features p, .testimonials p, .experience p, .award-models p, .contact-us p {
    margin: 0;
    width: 100%;
    text-align: left;
  }

  .features h2, .testimonials h2, .experience h2, .award-models h2, .cases h2 {
    text-align: left;
  }

  .accordion .accordion-screen, .accordion .accordion-screen.active {
    width: 90% !important;
    padding: 0px 0px 10px 20px !important;
  }

  .accordion .accordion-info {
    position: relative !important;
    float: none !important;
  }

  .accordion .accordion-info .active img {
    width: 275px !important;
  }

  button, .cases .cases-box a, .footer .menu-button{
    width: 50%;
    margin-bottom: 15px;
  }

  .testimonials-box .testimonials-half, .experience-boxes, .award-models .models-info, .cases-boxes, .footer, .footer .menu-items ul, .solutions .solutions-first-screen, .use-cases, .blog-lists, .single-blog-post, footer .company-part  {
    flex-direction: column;
  }

  .testimonials-box .testimonials-half .testimonials-info {
    padding: 20px;
  }

  .experience-box {
    margin-bottom: 15px;
  }
  
  .contact-us .contact-us-form input[type=text], .contact-us .contact-us-form input[type=email] {
    width: 90%;
  }

  .pricing-heading {
    height: 180px !important;
  }

  .pricing h1, .solutions .solutions-first-screen h1, .use-cases-page h1 {
    font-size: 55px !important;
  }

  .discover h1 {
    font-size: 35px !important;
  }

  .pricing .page-heading p {
    font-size: 25px !important;
  }

  .footer .menu-items ul li {
    text-align: center;
    margin-bottom: 5%;
  }

  footer .social-icons, .thank-you h1, .sub-form, .consultant-thank-you h1 {
    text-align: center;
  }

  .consultant-thank-you-center {
    flex-direction: column;
    height: 100%;
  }

  .consultant-thank-you {
    height: 350vh;
  }

  .menu-button {
    margin: 0 auto;
  }

  .centered, .cases .cases-box a {
    width: 90% !important;
  }

  .cases .cases-box img, .cases .cases-box p, .cases .cases-box h3 {
    width: 100%;
  }

  .use-cases {
    padding-bottom: 0px !important;
  }

  .use-cases-img {
    margin-bottom: 50px;
  }

  .use-cases-form {
    padding: 40px 40px 40px 70px !important;
  }

  .closeBtn {
    margin-top: 30px !important;
    margin-right: 30px !important;
  }

  /* #hideMe {
    left: 5% !important;
    top: 60px !important;
    width: 373px !important;
    height: 773px !important;
    border-radius: 10px;
  } */

  .accordion .accordion-screen.active {
    box-shadow: none !important;
  }

  .features-accordion-heading, .features-accordion-content {
    display: flex;
    flex-direction: column;
}

.features-accordion, .solutions-boxes {
  flex-direction: column;
}

.features-accordion-heading, .features-accordion-content, .features-half div, .single-blog-post .content {
  width: 100% !important;
}

.single-blog-post .sub-form {
  width: 115% !important;
}

.sub-frame > html {
  overflow: hidden !important;
}

.solutions-accordion .solutions-accordion-screen, .solutions-accordion .solutions-accordion-info {
  width: 90% !important;
}

.blog-lists a {
  width: 100% !important;
}

.blog-box .blog-info {
  height: 470px !important;
}

.single-blog-post .content {
  margin: 0px !important;
}

.single-blog-post h2 {
  margin-top: 5% !important;
}

.sub-form .quick_form_16_css {
  border: none !important;
}

.sub-form .sub-frame #customForm {
  overflow: hidden !important;
}

.sub-form iframe {
  height: 350px;
}

footer .company-part {
  align-items: center;
}

footer .company-part div:first-child, footer .company-part div:last-child, footer .menu-items p, footer .company-part p {
  width: 100%;
}

footer .company-part div:last-child {
  text-align: center;
}



}
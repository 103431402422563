.testimonials {
    background-color: #e9f9f0;
    padding: 50px 0px 30px 0px;
}

.testimonials p {
    margin: 0 auto;
}

.testimonials-box {
    background-color: #bfeed3;
    border-radius: 30px;
    width: 70%;
    margin: 30px auto 40px;
}

.testimonials-box .testimonials-half {
    display: flex;
    align-items: center;
    padding-top: 20px;
} 

.testimonials-box .testimonials-half .testimonials-info {
    text-align: left;
} 

.testimonials-box .testimonials-half .testimonials-info p:first-child {
    margin-bottom: 20px;
}


/* .testimonials-box .testimonials-half .testimonials-image {
    margin-right: 20px;
}  */

.testimonials-box .testimonials-half .testimonials-image img {
    width: 250px;
    vertical-align: bottom;
} 

.testimonials-dots {
    display: flex;
    justify-content: center;
}

.testimonials-dots div {
    margin-right: 10px;
    font-size: 30px;
    cursor: pointer;
}

.testimonials-slider span.swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background-color: transparent;
    border: 2px solid #002255;
    opacity: 1;
}

.testimonials-slider span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #002255;
} 

.testimonials-slider .swiper-button-next, .testimonials-slider .swiper-button-prev {
    display: none;
}